<!--营业执照信息-->
<template>
  <div>
    <!-- <div class="topDiv">
      <span class="longTextBtn finger btnReset"> 模版下载 </span>
      <span class="longTextBtn finger btnReset marginLeft20"> 模版导入 </span>
    </div> -->
    <div class="fromDiv">
      <div class="overflow">
        <div class="over">
          <span class="remarksAlink">企查查官网：<a target="_blank" href="https://www.qcc.com/">https://www.qcc.com/ </a> 操作简要：点击进入网站-在查询栏输入公司名称-找到该公司的营业执照信息，复制粘贴即可完成信息维护。 </span>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="营业执照" :showIcon="true" :fontColor="isUpdate('license_file_name')"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input v-model="business_license.license_file_name" :clearable="!is_readonly" @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" label="上传" @get-key="getKey(...arguments, 'license_file_key')" :disabled="is_readonly" :data="data1"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile('license_file_name')">{{ business_license.license_file_name }}</span> -->
          <span class="lookupImg" v-if="business_license.company_name && this.business_license.license_file_key" @click="openFile('license_file_name')">{{ business_license.company_name }}-营业执照</span>
          <span class="lookupImg" v-else-if="!business_license.company_name && this.business_license.license_file_key" @click="openFile('license_file_name')">-营业执照</span>
        </div>
        <FromLabel label="合同" :fontColor="isUpdate('contract_file_name')"></FromLabel>
        <div class="formFrIpt fl">
          <Input v-model="business_license.contract_file_name" :clearable="!is_readonly" @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" label="上传" @get-key="getKey(...arguments, 'invoice_head')" :disabled="is_readonly" :data="data2"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile('contract_file_name')">{{ business_license.contract_file_name }}</span> -->
          <span class="lookupImg" v-if="business_license.company_name && this.business_license.contract_file_key" @click="openFile('contract_file_name')">{{ business_license.company_name }}-合同</span>
          <span class="lookupImg" v-else-if="!business_license.company_name && this.business_license.contract_file_key" @click="openFile('license_file_name')">-合同</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业名称" :showIcon="true" :fontColor="isUpdate('company_name')"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" :disabled="Boolean(is_approved)" v-model="business_license.company_name" :readonly="is_readonly"></i-input>
        </div>
        <FromLabel label="国外注册公司" v-if="type === 1" :fontColor="isUpdate('foreign_company')"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 1">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.foreign_company" :readonly="is_readonly"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true" v-if="type === 2" :fontColor="isUpdate('unified_code')"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 2">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.unified_code" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型" :fontColor="isUpdate('company_type')"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.company_type" :readonly="is_readonly"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true" v-if="type === 1" :fontColor="isUpdate('unified_code')"></FromLabel>
        <div class="formFrIpt fl" v-if="type === 1">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.unified_code" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <!-- <div class="formLine borderTopNone">
        <FromLabel label="公司地址"></FromLabel>
        <div class="allWidth">
          <Select filterable clearable @on-change="changeProvince" :fontColor="isUpdate('company_name')" v-model="business_license.province" :readonly="is_readonly" class="iviewIptWidth165 marginRight10">
            <Option v-for="(item, index) in provinceList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <Select filterable clearable :fontColor="isUpdate('company_name')" v-model="business_license.city" :readonly="is_readonly" class="iviewIptWidth165 marginRight10">
            <Option v-for="(item, index) in cityList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <i-input placeholder="请输入" class="iviewIptWidth725" :fontColor="isUpdate('company_name')" v-model="business_license.address" :readonly="is_readonly"></i-input>
        </div>
      </div> -->
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true" :fontColor="isUpdate('legal_person')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.legal_person" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立日期" :fontColor="isUpdate('establish_date')"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="business_license.establish_date" @on-change="changeDate('establish_date', $event)" :readonly="is_readonly"></DatePicker>
        </div>
        <FromLabel label="注册资本" :fontColor="isUpdate('registered_capital')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="business_license.registered_capital" :readonly="is_readonly"></i-input>
          <span class="afterWords10">万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="营业期限" :showIcon="true" :fontColor="isUpdate('business_start_date') || isUpdate('business_end_date') || isUpdate('is_long_term')"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="business_license.business_start_date" @on-change="changeTime($event, 1)" :readonly="is_readonly"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="business_license.business_end_date" @on-change="changeTime($event, 2)" :options="options" :readonly="business_license.is_long_term == 1 || is_readonly"></DatePicker>
          <Checkbox v-model="business_license.is_long_term" @on-change="onChange" :true-value="1" :false-value="0" style="margin-left: 20px;" :disabled="is_readonly">长期</Checkbox>
        </div>
        <FromLabel label="登记日期" :showIcon="true" :fontColor="isUpdate('registration_date')"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="business_license.registration_date" @on-change="changeDate('registration_date', $event)" :readonly="is_readonly"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="formLabel fl" :class="{ redColor: isUpdate('business_scope') }">
          <div>
            <span class="icon">*</span>
            <span>经营范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" placeholder="请输入" class="iviewIptWidth1075" maxlength="500" show-word-limit v-model="business_license.business_scope" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel :showIcon="true" label="住所" :fontColor="isUpdate('registered_capital')"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="business_license.residence" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备注" :fontColor="isUpdate('other')"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="business_license.other" :readonly="is_readonly"></i-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      data1: {},
      data2: {},
      // 市
      // cityList: [],
      // 省
      // provinceList: [],
      options: {},
      // 发票单号-回显
      file_name2: '',
      // 营业执照-回显
      file_name1: '',
      // 营业执照信息
      business_license: {},
    }
  },
  props: {
    type: Number,
    business_license_props: Object,
    b_near_update_field: Array,
    is_readonly: Boolean || false,
    is_approved: Number,
  },
  watch: {
    // 初始化 business_license 值
    business_license_props: {
      handler(newValue, oldValue) {
        // 如果截止日期为'9999-12-31'则置空
        if (newValue.business_end_date == '9999-12-31') {
          newValue.business_end_date = null
        }
        // 处理单选-长期
        if (!newValue.is_long_term) {
          newValue.is_long_term = 0
        }
        this.business_license = newValue
      },
      deep: true,
      immediate: true,
    },
    // 侦听对象-深度侦听-子传父
    businessLicense: {
      handler(newValue, oldValue) {
        let syncField = []
        // 企业名称
        if (newValue.company_name != oldValue.company_name) {
          syncField = ['company_name', newValue.company_name]
        }
        // 法人
        if (newValue.legal_person != oldValue.legal_person) {
          syncField = ['legal_person', newValue.legal_person]
        }
        // 住所
        if (newValue.residence != oldValue.residence) {
          syncField = ['residence', newValue.residence]
        }
        this.$emit('information', 'business_license', this.business_license, syncField)
      },
      deep: true,
    },
  },
  // 解决深度监听新旧值打印一样问题
  computed: {
    businessLicense() {
      return JSON.parse(JSON.stringify(this.business_license))
    },
  },
  mounted() {
    if (this.$route.query.supplier_id) {
      this.data1 = {
        supplier_id: this.$route.query.supplier_id,
        table_type: 1,
      }
      this.data2 = {
        supplier_id: this.$route.query.supplier_id,
        table_type: 2,
      }
    } else {
      this.data1 = {
        table_type: 1,
      }
      this.data2 = {
        table_type: 2,
      }
    }
    // this.zoneList()
  },
  methods: {
    // 修改的值标红
    isUpdate(field) {
      return this.b_near_update_field.includes(field)
    },
    // 选择 长期 时清空截止日期
    onChange() {
      this.business_license.business_end_date = ''
    },
    // 地区下拉列表-市
    // changeProvince(foo) {
    //   if (foo === undefined) {
    //     return
    //   }
    //   let data = {
    //     parent_id: String(foo)
    //   }
    //   this.$http.get(this.$api.zoneList, data).then(res => {
    //     this.cityList = res.data
    //   })
    // },
    // 地区下拉列表-省
    // zoneList() {
    //   let data = {
    //     parent_id: '0'
    //   }
    //   this.$http.get(this.$api.zoneList, data).then(res => {
    //     this.provinceList = res.data
    //   })
    // },
    // 打开上传的文件
    openFile(field) {
      switch (field) {
        case 'license_file_name':
          window.open(this.business_license.license_file_key_url)
          break
        case 'contract_file_name':
          window.open(this.business_license.contract_file_key_url)
          break
        default:
          break
      }
    },
    // 清空上传文件
    clearUploadFile() {
      delete this.business_license.license_file_key
      delete this.business_license.invoice_head
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      switch (field) {
        case 'license_file_key': // 营业执照
          this.business_license.license_file_key = key
          this.business_license.license_file_key_url = url
          this.business_license.license_file_name = this.business_license.company_name
          break
        case 'invoice_head': // 合同
          this.business_license.contract_file_key = key
          this.business_license.contract_file_key_url = url
          this.business_license.contract_file_name = this.business_license.company_name
          break
        default:
          break
      }
    },
    changeDate(field, e) {
      this.business_license[field] = e
    },
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.business_license.business_start_date = e
        if (e > this.business_license.business_end_date) {
          this.business_license.business_end_date = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.business_license.business_start_date) - 86400000
          },
        }
      } else {
        this.business_license.business_end_date = e
      }
    },
  },
}
</script>

<style scoped lang="less">
.iviewIptWidth725 {
  width: 725px !important;
}

.lookupImg {
  width: 200px;
}

.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 68px;
    transform: translate(50%, -50%);
  }
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.moreHeight {
  height: 150px;
}

.formLabel {
  height: 150px !important;
  line-height: 150px !important;
}
.over {
  margin-bottom: 10px;
}
</style>
