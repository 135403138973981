<template>
  <div class="content">
    <div class="tableTitle" style="margin-top: -10px;">
      <span class="title"> 采购部审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input v-model="business_review.purchase_advice" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '1')" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="business_review.purchase_sign" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '1')" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
          <RadioGroup v-model="business_review.purchase_review_status">
            <Radio label="1" class="marginRight20" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '1')">同意</Radio>
            <Radio label="0" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '1')">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker :value="business_review.purchase_sign_date" @on-open-change="openChange('purchase_sign_date', $event)" @on-change="changeDate('purchase_sign_date', $event)" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '1')" type="datetime" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 质量管理部门审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input v-model="business_review.quality_advice" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '2')" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="business_review.quality_sign" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '2')" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
          <RadioGroup v-model="business_review.quality_review_status">
            <Radio label="1" class="marginRight20" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '2')">同意</Radio>
            <Radio label="0" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '2')">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker :value="business_review.quality_sign_date" @on-open-change="openChange('quality_sign_date', $event)" @on-change="changeDate('quality_sign_date', $event)" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '2')" type="datetime" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 财务部审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input v-model="business_review.finance_advice" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '3')" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="business_review.finance_sign" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '3')" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
          <RadioGroup v-model="business_review.finance_review_status">
            <Radio label="1" class="marginRight20" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '3')">同意</Radio>
            <Radio label="0" :disabled="is_readonly || !(trueAuthority === '0' || trueAuthority === '3')">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker :value="business_review.finance_sign_date" @on-open-change="openChange('finance_sign_date', $event)" @on-change="changeDate('finance_sign_date', $event)" :readonly="is_readonly" :disabled="!(trueAuthority === '0' || trueAuthority === '3')" type="datetime" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 总经理意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input v-model="business_review.manager_advice" :readonly="is_readonly" :disabled="!(trueAuthority === '0')" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="business_review.manager_sign" :readonly="is_readonly" :disabled="!(trueAuthority === '0')" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
          <RadioGroup v-model="business_review.manager_review_status">
            <Radio label="1" class="marginRight20" :disabled="is_readonly || !(trueAuthority === '0')">同意</Radio>
            <Radio label="0" :disabled="is_readonly || !(trueAuthority === '0')">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker :value="business_review.manager_sign_date" @on-open-change="openChange('manager_sign_date', $event)" @on-change="changeDate('manager_sign_date', $event)" :readonly="is_readonly" :disabled="!(trueAuthority === '0')" type="datetime" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'businessApproval',
  components: {
    FromLabel,
  },
  data() {
    return {
      // 首营审核
      business_review: {},
      // 权限
      trueAuthority: '',
    }
  },
  props: {
    business_review_props: Object,
    is_readonly: Boolean || false,
  },
  watch: {
    // 初始化 business_review 值
    business_review_props: {
      handler(val, oldValue) {
        // 单选处理
        val.purchase_review_status = val.purchase_review_status || val.purchase_review_status === 0 ? String(val.purchase_review_status) : null
        val.quality_review_status = val.quality_review_status || val.quality_review_status === 0 ? String(val.quality_review_status) : null
        val.finance_review_status = val.finance_review_status || val.finance_review_status === 0 ? String(val.finance_review_status) : null
        val.manager_review_status = val.manager_review_status || val.manager_review_status === 0 ? String(val.manager_review_status) : null

        this.business_review = val
      },
      deep: true,
      immediate: true,
    },
    // 侦听对象-深度侦听-子传父
    business_review: {
      handler(newValue, oldValue) {
        this.$emit('information', 'business_review', this.business_review)
      },
      deep: true,
    },
  },
  mounted() {
    this.getUserRole()
  },
  methods: {
    // 改变时间
    openChange(name, e) {
      if (!this.business_review[name]) {
        this.business_review[name] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }
    },
    changeDate(field, e) {
      this.business_review[field] = e
    },
    // 获取账号权限
    getUserRole() {
      let userRole = window.localStorage.getItem('userRole')
      switch (userRole) {
        case '1': // 超管
        case '2': // 总经理
          this.trueAuthority = '0'
          break
        case '3': // 采购主管
        case '4': // 采购员
          this.trueAuthority = '1'
          break
        case '8': // 质量管理员
          this.trueAuthority = '2'
          break
        case '10': // 财务主管
        case '11': // 财务专员
          this.trueAuthority = '3'
          break
        case '15': // 质量负责人
          this.trueAuthority = '2'
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped lang="less">
// .content {
//   padding: 0px 30px;
// }
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 10px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
.marginRight20 {
  margin-right: 20px;
}
/deep/ .fullInput {
  width: 100%;
  height: 85px;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #e8eaec;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  textarea.ivu-input {
    height: 100% !important;
  }
}
</style>
