<template>
  <div class="page">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist"
          :key="index"
          :class="{ clickActive: clickIndex == item.type, finger: true }"
          @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear"
             v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="mainBox">
      <div class="tabelDiv">
        <!--营业执照信息-->
        <BusinessLicenseInfo v-show="clickIndex == 1"
                             :business_license_props="business_license"
                             :b_near_update_field="b_near_update_field"
                             @information="information"
                             :is_readonly="is_readonly"
                             :is_approved="is_approved"
                             :type="supplier_type"></BusinessLicenseInfo>
        <!--生产许可证（经销商 无此）-->
        <ProductionLicense v-show="clickIndex == 2"
                           :production_license_props="production_license"
                           :p_near_update_field="p_near_update_field"
                           @information="information"
                           :is_approved="is_approved"
                           :is_readonly="is_readonly"> </ProductionLicense>
        <!--生产备案凭证（经销商 无此）-->
        <ProductionRecord v-show="clickIndex == 3"
                          :production_registration_license_props="production_registration_license"
                          :pr_near_update_field="pr_near_update_field"
                          @information="information"
                          :is_approved="is_approved"
                          :is_readonly="is_readonly"> </ProductionRecord>
        <!--经营许可证-->
        <OperatingLicense v-show="clickIndex == 4"
                          :operating_license_props="operating_license"
                          :o_near_update_field="o_near_update_field"
                          @information="information"
                          :is_approved="is_approved"
                          :is_readonly="is_readonly"></OperatingLicense>
        <!--经营备案凭证-->
        <RecordCertificate v-show="clickIndex == 5"
                           :registration_license_props="registration_license"
                           :r_near_update_field="r_near_update_field"
                           @information="information"
                           :is_approved="is_approved"
                           :is_readonly="is_readonly"> </RecordCertificate>
        <!--补充信息-->
        <AdditionalInfo v-show="clickIndex == 6"
                        :supplementary_props="supplementary"
                        :s_near_update_field="s_near_update_field"
                        @information="information"
                        :is_readonly="is_readonly"> </AdditionalInfo>
        <!-- 首营审批 -->
        <BusinessApproval v-show="clickIndex == 7"
                          :business_review_props="business_review"
                          @information="information"
                          :is_readonly="is_readonly"></BusinessApproval>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20"
             v-if="!is_readonly && this.clickIndex == 7"
             @click="save('1')">保存</div>
        <div class="longTextBtn finger btnSure fr marginLeft20"
             v-if="!is_readonly && clickIndex == 7 && userRole == '1'"
             @click="pass">一键通过</div>
        <span class="pageBtn finger btnReset fr"
              @click="back">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
// 营业执照信息
import BusinessLicenseInfo from './navDedail/businessLicenseInfo'
// 生产许可证（经销商 无此）
import ProductionLicense from './navDedail/productionLicense'
// 生产备案凭证（经销商 无此）
import ProductionRecord from './navDedail/productionRecord'
// 经营许可证
import OperatingLicense from './navDedail/operatingLicense'
// 经营备案凭证
import RecordCertificate from './navDedail/recordCertificate'
// 补充信息
import AdditionalInfo from './navDedail/additionalInfo'
// 首营审批
import BusinessApproval from './businessApproval'

export default {
  name: 'firstCampApproval',
  components: {
    BusinessLicenseInfo,
    ProductionLicense,
    ProductionRecord,
    OperatingLicense,
    RecordCertificate,
    AdditionalInfo,
    BusinessApproval,
  },
  data() {
    return {
      companyName:'',
      // 各Tab页被修改的值
      // 营业执照信息
      b_near_update_field: [],
      // 生产许可证
      p_near_update_field: [],
      // 生产备案凭证
      pr_near_update_field: [],
      // 经营许可证
      o_near_update_field: [],
      // 经营备案凭证
      r_near_update_field: [],
      // 补充信息
      s_near_update_field: [],

      // 各Tab页的值
      // 营业执照信息
      business_license: {},
      // 生产许可证
      production_license: {},
      // 生产备案凭证
      production_registration_license: {},
      // 经营许可证
      operating_license: {},
      // 经营备案凭证
      registration_license: {},
      // 补充信息
      supplementary: {},
      // 首营审批
      business_review: {},
      // 最初请求到的数据
      originalData: {},

      // 检验标记: 各Tab页是否校验无误，1无误，0有误
      // [营业执照信息, 生产许可证, 生产备案凭证, 营业许可证, 经营备案凭证, 补充信息]
      // 与导航栏Tab页顺序保持一致
      verifyIsSend: [0, 0, 0, 0, 0, 0],
      // 当前显示的Tab
      currentTab: 'business_license',
      // 导航
      navFrist: [
        {
          name: '营业执照信息',
          type: 1,
        },
      ],
      // 点击第几个Tab页
      clickIndex: 1,

      // 用户权限
      userRole: '',
      //
      business_review_id: undefined,
      // 供应商类型
      supplier_type: undefined,
      // 供应商id
      supplier_id: '',
      // 是否只读
      is_readonly: false,
      // 是否已审核
      is_approved: null,
    }
  },
  created() {
    // 获取账号权限
    /** 权限编码：
     * 1 超管
     * 2 总经理
     * 3 采购主管
     * 4 采购员
     * 8 质量管理员
     * 10 财务主管
     * 11 财务专员
     * */
    this.userRole = window.localStorage.getItem('userRole')
    // 是否只读
    this.is_readonly = !!this.$route.query.is_readonly
    // 获取供应商id
    this.supplier_id = this.$route.query.supplier_id
    // 获取供应商详情
    this.getSupplier()
  },
  methods: {
    /**
     * 校验部分
     * */
    // 校验 营业执照信息
    verify1() {
      // 校验必选项
      let bar = this.currentTab !== 'business_license' ? ' 营业执照信息 中的' : ''
      if (!this.business_license.license_file_name) {
        this.$Message.warning('请填写' + bar + '营业执照')
        return
      }
      if (!this.business_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.business_license.unified_code) {
        this.$Message.warning('请填写' + bar + '统一社会信用代码')
        return
      }
      if (!this.business_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.business_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '营业期限')
        return
      }
      if (this.business_license.is_long_term == 0) {
        if (!this.business_license.business_end_date) {
          this.$Message.warning('请填写' + bar + '营业期限')
          return
        }
      }
      if (!this.business_license.registration_date) {
        this.$Message.warning('请填写' + bar + '登记日期')
        return
      }
      if (!this.business_license.business_scope) {
        this.$Message.warning('请填写' + bar + '经营范围')
        return
      }
      if (!this.business_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.business_license.data_type || !this.business_license.data_type.length) {
        this.$Message.warning('请填写' + bar + '资料维护')
        return
      }
      this.verifyIsSend[0] = 1
    },
    // 校验 生产许可证
    verify2() {
      // 剔除空字符串的属性
      // for (let name in this.production_license) {
      //   if (this.production_license[name] === null) {
      //     delete this.production_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'production_license' ? ' 生产许可证 中的' : ''
      if (!this.production_license.license_file_name) {
        this.$Message.warning('请填写' + bar + '生产许可证')
        return
      }
      if (!this.production_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (!this.production_license.business_end_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (!this.production_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.production_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.production_license.production_address) {
        this.$Message.warning('请填写' + bar + '生产地址')
        return
      }
      if (!this.production_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.production_license.production_license_code) {
        this.$Message.warning('请填写' + bar + '许可证编号')
        return
      }
      if (!this.production_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.production_license.production_scope) {
        this.$Message.warning('请填写' + bar + '生产范围')
        return
      }
      if (!this.production_license.license_date) {
        this.$Message.warning('请填写' + bar + '发证日期')
        return
      }
      this.verifyIsSend[1] = 1
    },
    // 校验 生产备案凭证
    verify3() {
      // 剔除空字符串的属性
      // for (let name in this.production_registration_license) {
      //   if (this.production_registration_license[name] === null) {
      //     delete this.production_registration_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'production_registration_license' ? ' 生产备案凭证 中的' : ''
      if (!this.production_registration_license.license_file_name) {
        this.$Message.warning('请填写' + bar + '备案凭证')
        return
      }
      if (!this.production_registration_license.production_registration_license_code) {
        this.$Message.warning('请填写' + bar + '备案号')
        return
      }
      if (!this.production_registration_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.production_registration_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.production_registration_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.production_registration_license.production_address) {
        this.$Message.warning('请填写' + bar + '生产地址')
        return
      }
      if (!this.production_registration_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.production_registration_license.production_scope) {
        this.$Message.warning('请填写' + bar + '生产范围')
        return
      }
      if (!this.production_registration_license.license_date) {
        this.$Message.warning('请填写' + bar + '备案日期')
        return
      }
      this.verifyIsSend[2] = 1
    },
    // 校验 经营许可证
    verify4() {
      // 剔除空字符串的属性
      // for (let name in this.operating_license) {
      //   if (this.operating_license[name] === null) {
      //     delete this.operating_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'operating_license' ? ' 经营许可证 中的' : ''
      if (!this.operating_license.license_file_name) {
        this.$Message.warning('请填写' + bar + '经营许可证')
        return
      }
      if (!this.operating_license.business_start_date) {
        this.$Message.warning('请填写' + bar + '有效期限')
        return
      }
      if (this.operating_license.is_long_term == 0) {
        if (!this.operating_license.business_end_date) {
          this.$Message.warning('请填写' + bar + '有效期限')
          return
        }
      }
      if (!this.operating_license.operating_license_code) {
        this.$Message.warning('请填写' + bar + '许可证编号')
        return
      }
      if (!this.operating_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.operating_license.operating_type || this.operating_license.operating_type == 'undefined' || this.operating_license.operating_type == 'null') {
        this.$Message.warning('请填写' + bar + '经营方式')
        return
      }
      if (!this.operating_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.operating_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.operating_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.operating_license.premise) {
        this.$Message.warning('请填写' + bar + '经营场所')
        return
      }
      if (!this.operating_license.warehouse_address) {
        this.$Message.warning('请填写' + bar + '库房地址')
        return
      }
      this.verifyIsSend[3] = 1
    },
    // 校验 经营备案凭证
    verify5() {
      // 剔除空字符串的属性
      // for (let name in this.registration_license) {
      //   if (this.registration_license[name] === null) {
      //     delete this.registration_license[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'registration_license' ? ' 经营备案凭证 中的' : ''
      if (!this.registration_license.license_file_name) {
        this.$Message.warning('请填写' + bar + '备案凭证')
        return
      }
      if (!this.registration_license.registration_license_code) {
        this.$Message.warning('请填写' + bar + '备案号')
        return
      }
      if (!this.registration_license.registration_date) {
        this.$Message.warning('请填写' + bar + '备案日期')
        return
      }
      if (!this.registration_license.company_name) {
        this.$Message.warning('请填写' + bar + '企业名称')
        return
      }
      if (!this.registration_license.operating_type || this.registration_license.operating_type == 'undefined' || this.registration_license.operating_type == 'null') {
        this.$Message.warning('请填写' + bar + '经营方式')
        return
      }
      if (!this.registration_license.legal_person) {
        this.$Message.warning('请填写' + bar + '法人')
        return
      }
      if (!this.registration_license.incharge_person) {
        this.$Message.warning('请填写' + bar + '企业负责人')
        return
      }
      if (!this.registration_license.residence) {
        this.$Message.warning('请填写' + bar + '住所')
        return
      }
      if (!this.registration_license.premise) {
        this.$Message.warning('请填写' + bar + '经营场所')
        return
      }
      if (!this.registration_license.warehouse_address) {
        this.$Message.warning('请填写' + bar + '库房地址')
        return
      }
      this.verifyIsSend[4] = 1
    },
    // 校验 补充信息
    verify6() {
      // 剔除空字符串的属性
      // for (let name in this.supplementary) {
      //   if (this.supplementary[name] === null) {
      //     delete this.supplementary[name]
      //   }
      // }
      // 校验必选项
      let bar = this.currentTab !== 'supplementary' ? ' 补充信息 中的' : ''
      if (!this.supplementary.license_file_name) {
        this.$Message.warning('请填写' + bar + '授权信息')
        return
      }
      if (!this.supplementary.name) {
        this.$Message.warning('请填写' + bar + '被授权人姓名')
        return
      }
      if (!this.supplementary.certid) {
        this.$Message.warning('请填写' + bar + '证件号码')
        return
      }
      if (!this.supplementary.phone) {
        this.$Message.warning('请填写' + bar + '手机号')
        return
      }
      if (!this.supplementary.email) {
        this.$Message.warning('请填写' + bar + '邮箱')
        return
      }
      if (!this.supplementary.license_start_date) {
        this.$Message.warning('请填写' + bar + '授权日期')
        return
      }
      if (this.supplementary.is_long_term == 0) {
        if (!this.supplementary.license_end_date) {
          this.$Message.warning('请填写' + bar + '授权日期')
          return
        }
      }
      if (!this.supplementary.invoice_head) {
        this.$Message.warning('请填写' + bar + '发票抬头')
        return
      }
      if (!this.supplementary.invoice_no) {
        this.$Message.warning('请填写' + bar + '税号')
        return
      }
      if (!this.supplementary.invoice_bank) {
        this.$Message.warning('请填写' + bar + '开户银行')
        return
      }
      if (!this.supplementary.invoice_bankno) {
        this.$Message.warning('请填写' + bar + '银行账号')
        return
      }
      this.verifyIsSend[5] = 1
    },
    // 发送前检验（相当于供应商端的提交）：营业执照信息 + 资料维护选中的Tab页 + 补充信息
    beforeSend() {
      // 初始化 检验标记
      // [营业执照信息, 生产许可证, 生产备案凭证, 营业许可证, 经营备案凭证, 补充信息]
      this.verifyIsSend = [0, 0, 0, 0, 0, 0]
      // 校验 营业执照信息
      // this.verify1()
      // if (!this.verifyIsSend[0]) return
      // 校验 资料维护选中的Tab页（Tab栏从左到右校验）
      for (let item of this.navFrist) {
        switch (item.type) {
          case 1:
            this.verify1()
            if (!this.verifyIsSend[0]) return
            break
          case 2:
            this.verify2()
            if (!this.verifyIsSend[1]) return
            break
          case 3:
            this.verify3()
            if (!this.verifyIsSend[2]) return
            break
          case 4:
            this.verify4()
            if (!this.verifyIsSend[3]) return
            break
          case 5:
            this.verify5()
            if (!this.verifyIsSend[4]) return
            break
          case 6:
            this.verify6()
            if (!this.verifyIsSend[5]) return
            break
          default:
            break
        }
      }
    },

    /**
     * 保存、一键通过
     * */
    // 一键通过
    pass() {
      this.$http.get(this.$api.supplierOnePass).then(res => {
        // 审核意见
        this.business_review.purchase_advice = res.data.purchase_advice
        this.business_review.quality_advice = res.data.quality_advice
        this.business_review.finance_advice = res.data.finance_advice
        this.business_review.manager_advice = res.data.manager_advice
        // 负责人签字
        this.business_review.purchase_sign = res.data.purchase_sign
        this.business_review.quality_sign = res.data.quality_sign
        this.business_review.finance_sign = res.data.finance_sign
        this.business_review.manager_sign = res.data.manager_sign
        // 单选
        this.business_review.purchase_review_status = String(res.data.purchase_review_status)
        this.business_review.quality_review_status = String(res.data.quality_review_status)
        this.business_review.finance_review_status = String(res.data.finance_review_status)
        this.business_review.manager_review_status = String(res.data.manager_review_status)
        // 签字日期
        this.business_review.purchase_sign_date = this.$moment(res.data.purchase_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.business_review.quality_sign_date = this.$moment(res.data.quality_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.business_review.finance_sign_date = this.$moment(res.data.finance_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.business_review.manager_sign_date = this.$moment(res.data.manager_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss')
        // 保存-首营审批
        this.save_1_6('1')
        // 更新一级页面的数据
        sessionStorage.setItem('updataCache','0')
      })
    },
    // 保存-首营审批
    save7() {
      // 校验
      this.beforeSend()
      // for (let item of this.navFrist) {
      //   if (item.type == 7) break
      //   if (!this.verifyIsSend[item.type - 1]) return
      // }
      // 将发送的数据
      let data = { business_review_id: this.business_review_id }
      Object.assign(data, this.business_review)
      this.$http.post(this.$api.supplierBusinessReview, data, true).then(res => {
        if (res.status) {
          // this.$Message.success('保存成功')
          this.$router.replace({
            path: '/factoryManage',
          })
        } else {
          this.$Message.error('保存失败')
        }
      })
    },
    // 保存-除 首营审批
    save_1_6(supplier_state) {
      // 校验
      this.beforeSend()
      // for (let item of this.navFrist) {
      //   if (item.type == 7) break
      //   if (!this.verifyIsSend[item.type - 1]) return
      // }

      // if (this.verifyIsSend.includes(0)) return
      // let table_id = ''
      // switch (this.currentTab){
      //   case 'business_license':
      //     table_id = 'business_license_id'
      //     break;
      //   case 'production_license':
      //     table_id = 'production_license_id'
      //     break;
      //   case 'production_registration_license':
      //     table_id = 'production_registration_license_id'
      //     break;
      //   case 'operating_license':
      //     table_id = 'operating_license_id'
      //     break;
      //   case 'registration_license':
      //     table_id = 'registration_license_id'
      //     break;
      //   case 'supplementary':
      //     table_id = 'supplementary_id'
      //     break;
      //   case 'business_review':
      //     table_id = 'business_review_id'
      //     break;
      //   default:
      //     break;
      // }
      // 将发送的数据
      let data = {}
      // 必传信息
      data.supplier_state = supplier_state
      data.supplier_id = this.supplier_id
      data.supplier_type = this.supplier_type
      // data[table_id] = this[table_id]
      /**
       * 一起发送
       * */
      data.business_license = this.business_license // 营业执照信息
      delete data.business_license.license_file_key_url
      delete data.business_license.contract_file_key_url
      // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
      if (data.business_license.is_long_term) {
        data.business_license.business_end_date = '9999-12-31'
      }
      for (let item of this.navFrist) {
        // [1营业执照信息, 2生产许可证, 3生产备案凭证, 4营业许可证, 5经营备案凭证, 6补充信息]
        switch (item.type) {
          case 2:
            // 剔除空字符串的属性
            for (let name in this.production_license) {
              if (this.production_license[name] === null) {
                delete this.production_license[name]
              }
            }
            data.production_license = this.production_license
            delete data.production_license.license_file_key_url
            break
          case 3:
            // 剔除空字符串的属性
            for (let name in this.production_registration_license) {
              if (this.production_registration_license[name] === null) {
                delete this.production_registration_license[name]
              }
            }
            data.production_registration_license = this.production_registration_license
            delete data.production_registration_license.license_file_key_url
            break
          case 4:
            // 剔除空字符串的属性
            for (let name in this.operating_license) {
              if (this.operating_license[name] === null || this.operating_license[name] == 'undefined' || this.operating_license[name] == 'null') {
                delete this.operating_license[name]
              }
            }
            data.operating_license = this.operating_license
            delete data.operating_license.license_file_key_url
            // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
            if (data.operating_license.is_long_term) {
              data.operating_license.business_end_date = '9999-12-31'
            }
            if (!data.operating_license.business_end_date) {
              data.operating_license.business_end_date = null
            }
            break
          case 5:
            // 剔除空字符串的属性
            for (let name in this.registration_license) {
              if (this.registration_license[name] === null || this.registration_license[name] == 'undefined' || this.registration_license[name] == 'null') {
                delete this.registration_license[name]
              }
            }
            data.registration_license = this.registration_license
            delete data.registration_license.license_file_key_url
            break
          case 6:
            // 剔除空字符串的属性
            for (let name in this.supplementary) {
              if (this.supplementary[name] === null) {
                delete this.supplementary[name]
              }
              delete this.supplementary['company_name']
            }
            data.supplementary = this.supplementary
            delete data.supplementary.license_file_key_url
            // 如果选择了长期则截止日期传'9999-12-31'，方便后端排序
            if (data.supplementary.is_long_term) {
              data.supplementary.license_end_date = '9999-12-31'
            }
            break
          default:
            break
        }
      }
      // 发送
      this.$http
        .post(this.$api.supplierSave, data, true)
        .then(res => {
          if (res.status) {
            this.$Message.success('保存成功')
            this.getSupplier()
          } else {
            this.$Message.error('保存失败')
          }
        })
        .then(res => {
          this.save7()
        })
    },
    // 保存-总
    save(supplier_state) {
      // 调用不同的保存接口
      // if (this.clickIndex == 7) {
      // 保存-首营审批
      this.save_1_6(supplier_state)
      // } else {
      //   // 保存-除 首营审批
      //   this.save_1_6(supplier_state)
      // }
      sessionStorage.setItem('updataCache','0')
    },

    /**
     * 改动数据
     * */
    // 页面信息同步
    pageSyncData(bar, syncField) {
      // 补充信息、首营审批Tab页没有要同步的信息
      if (this.currentTab == 'business_review') return
      for (let item of this.navFrist) {
        // [1营业执照信息, 2生产许可证, 3生产备案凭证, 4营业许可证, 5经营备案凭证, 6补充信息]
        switch (item.type) {
          case 1:
            if (syncField[0] in this.business_license) {
              this.business_license[syncField[0]] = syncField[1]
            }
            break
          case 2:
            if (syncField[0] in this.production_license) {
              this.production_license[syncField[0]] = syncField[1]
            }
            break
          case 3:
            if (syncField[0] in this.production_registration_license) {
              this.production_registration_license[syncField[0]] = syncField[1]
            }
            break
          case 4:
            if (syncField[0] in this.operating_license) {
              this.operating_license[syncField[0]] = syncField[1]
            }
            break
          case 5:
            if (syncField[0] in this.registration_license) {
              this.registration_license[syncField[0]] = syncField[1]
            }
            break
          case 6:
            if (syncField[0] in this.supplementary) {
              this.supplementary[syncField[0]] = syncField[1]
              this.supplementary = JSON.parse(JSON.stringify(this.supplementary))
            }
            break
          default:
            break
        }
      }
    },
    // 监听子组件传来的数据
    information(bar, foo, syncField) {
      // 接收子组件的传值
      switch (bar) {
        case 'business_license':
          this.business_license = foo
          break
        case 'production_license':
          this.production_license = foo
          break
        case 'operating_license':
          this.operating_license = foo
          break
        case 'registration_license':
          this.registration_license = foo
          break
        case 'supplementary':
          this.supplementary = foo
          break
        default:
          break
      }
      // 同步各Tab页部分数据
      if (syncField) {
        this.pageSyncData(bar, syncField)
      }
    },

    /**
     * 请求数据
     * */
    // 请求到的-处理日期格式
    processingDate() {
      // 营业执照信息-时间处理
      this.business_license.establish_date = this.business_license.establish_date ? this.$moment(new Date(this.business_license.establish_date * 1000)).format('YYYY-MM-DD') : null
      this.business_license.business_start_date = this.business_license.business_start_date ? this.$moment(new Date(this.business_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.business_license.business_end_date = this.business_license.business_end_date ? this.$moment(new Date(this.business_license.business_end_date * 1000)).format('YYYY-MM-DD') : null
      this.business_license.registration_date = this.business_license.registration_date ? this.$moment(new Date(this.business_license.registration_date * 1000)).format('YYYY-MM-DD') : null

      // 生产许可证-时间处理
      this.production_license.business_start_date = this.production_license.business_start_date ? this.$moment(new Date(this.production_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.production_license.business_end_date = this.production_license.business_end_date ? this.$moment(new Date(this.production_license.business_end_date * 1000)).format('YYYY-MM-DD') : null
      this.production_license.license_date = this.production_license.license_date ? this.$moment(new Date(this.production_license.license_date * 1000)).format('YYYY-MM-DD') : null

      // 生产备案凭证-时间处理
      this.production_registration_license.license_date = this.production_registration_license.license_date ? this.$moment(new Date(this.production_registration_license.license_date * 1000)).format('YYYY-MM-DD') : null

      // 营业许可证-时间处理
      this.operating_license.business_start_date = this.operating_license.business_start_date ? this.$moment(new Date(this.operating_license.business_start_date * 1000)).format('YYYY-MM-DD') : null
      this.operating_license.business_end_date = this.operating_license.business_end_date ? this.$moment(new Date(this.operating_license.business_end_date * 1000)).format('YYYY-MM-DD') : null

      // 经营备案凭证-时间处理
      this.registration_license.registration_date = this.registration_license.registration_date ? this.$moment(new Date(this.registration_license.registration_date * 1000)).format('YYYY-MM-DD') : null

      // 补充信息-时间处理
      this.supplementary.license_start_date = this.supplementary.license_start_date ? this.$moment(new Date(this.supplementary.license_start_date * 1000)).format('YYYY-MM-DD') : null
      this.supplementary.license_end_date = this.supplementary.license_end_date ? this.$moment(new Date(this.supplementary.license_end_date * 1000)).format('YYYY-MM-DD') : null

      // 首营审批-时间处理
      this.business_review.purchase_sign_date = this.business_review.purchase_sign_date ? this.$moment(new Date(this.business_review.purchase_sign_date * 1000)).format('YYYY-MM-DD HH:mm:ss') : null
      this.business_review.quality_sign_date = this.business_review.quality_sign_date ? this.$moment(new Date(this.business_review.quality_sign_date * 1000)).format('YYYY-MM-DD HH:mm:ss') : null
      this.business_review.finance_sign_date = this.business_review.finance_sign_date ? this.$moment(new Date(this.business_review.finance_sign_date * 1000)).format('YYYY-MM-DD HH:mm:ss') : null
      this.business_review.manager_sign_date = this.business_review.manager_sign_date ? this.$moment(new Date(this.business_review.manager_sign_date * 1000)).format('YYYY-MM-DD HH:mm:ss') : null
    },
    // 获取供应商详情
    getSupplier() {
      let data = { supplier_id: this.supplier_id, is_supplier_side: '0' }
      this.$http.get(this.$api.getSupplier, data, true).then(res => {
        //
        this.business_review_id = res.data.business_review_id
        // 供应商类型
        this.supplier_type = res.data.supplier_type
        // 请求到的原始信息
        this.originalData = JSON.parse(JSON.stringify(res.data))

        // 各Tab页信息
        // 营业执照信息
        this.business_license = res.data.business_license
        // 经营许可证
        this.operating_license = res.data.operating_license
        // 经营备案凭证
        this.registration_license = res.data.registration_license
        // 生产许可证
        this.production_license = res.data.production_license
        // 生产备案凭证
        this.production_registration_license = res.data.production_registration_license
        // 补充信息
        this.supplementary = res.data.supplementary
        this.supplementary.company_name = res.data.business_license.company_name
        // 首营审批
        this.business_review = res.data.business_review

        // 各Tab页被修改的信息
        // 营业执照信息
        this.b_near_update_field = res.data.b_near_update_field
        // 经营许可证
        this.o_near_update_field = res.data.o_near_update_field
        // 经营备案凭证
        this.r_near_update_field = res.data.r_near_update_field
        // 生产许可证
        this.p_near_update_field = res.data.p_near_update_field
        // 生产备案凭证
        this.pr_near_update_field = res.data.pr_near_update_field
        // 补充信息
        this.s_near_update_field = res.data.s_near_update_field

        // 处理日期格式
        this.processingDate()
        // 是否已审核过
        this.is_approved = res.data.is_approved
        /**
         * 资料维护 1:经营许可证 2：(经营)备案凭证 3：生产许可证 4：生产备案凭证
         *  */
        // 根据 资料维护 定制导航
        let dataType = this.business_license.data_type
        this.navFrist = [
          {
            name: '营业执照信息',
            type: 1,
          },
        ]
        if (dataType.includes('3')) {
          this.navFrist.push({
            name: '生产许可证',
            type: 2,
          })
        }
        if (dataType.includes('4')) {
          this.navFrist.push({
            name: '生产备案凭证',
            type: 3,
          })
        }
        if (dataType.includes('1')) {
          this.navFrist.push({
            name: '经营许可证',
            type: 4,
          })
        }
        if (dataType.includes('2')) {
          this.navFrist.push({
            name: '经营备案凭证',
            type: 5,
          })
        }
        this.navFrist.push({
          name: '补充信息',
          type: 6,
        })
        this.navFrist.push({
          name: '首营审批',
          type: 7,
        })
      })
    },

    /**
     * 杂七杂八
     * */
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 切换Tab
    clickNav(type) {
      this.clickIndex = type
      // 校正 currentTab
      switch (this.clickIndex) {
        case 1:
          this.currentTab = 'business_license'
          break
        case 2:
          this.currentTab = 'production_license'
          break
        case 3:
          this.currentTab = 'production_registration_license'
          break
        case 4:
          this.currentTab = 'operating_license'
          break
        case 5:
          this.currentTab = 'registration_license'
          break
        case 6:
          this.currentTab = 'supplementary'
          break
        case 7:
          this.currentTab = 'business_review'
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .mainBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 16x 20px rgb(0, 0, 0);
    overflow-y: scroll;
  }
  .tabelDiv {
    flex: 1;
    border-radius: 0 8px 8px 8px;
    // padding: 20px 0px;
    padding: 20px 25px 30px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
.saveDiv {
  padding: 0 23px 29px 0;
}
</style>
