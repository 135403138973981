<template>
  <div>
    <Upload id="iviewUp" :max-size="20480" :data="data" :multiple="multiple" :show-upload-list="false" :on-progress="handleProgressQuill" :on-success="handleSuccessQuill" :on-error="handleErrorQuill" :format="['jpg', 'jpeg', 'png', 'pdf']" :headers="header" name="richTextAccessory"  :action="url" :on-format-error="onFormatError" :disabled="disabled">
      {{ label }}
    </Upload>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <!-- <div>上传中...</div> -->
    </Spin>
  </div>
</template>

<script>
import http from '@/service/baseUrl'
export default {
  name: 'formUpload',
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isSupplier:{
      type: Boolean,
      default: false,
    },
    data: Object,
    httpUrl: String,
  },
  data() {
    return {
      loading: false,
      url: '',
      header: '',
    }
  },
  created() {
    if(this.isSupplier){
      this.url = this.httpUrl ? http.http + this.httpUrl : http.http + this.$api.uploadFile.url
    }else{
      this.url = http.http + '/public/upload/file'
    }
    // console.log(this.url)
    this.header = {
      // Authorization: 'Bearer ' + localStorage.getItem('adminohoqicbi==')
      Authorization: 'Bearer ' + localStorage.getItem('adminohoqicbi=='),
    }
    // console.log(this.header)
  },
  methods: {
    // 文件类型校验
    onFormatError() {
      this.$Message.error('只允许上传jpg,jpeg,png,pdf格式的文件')
    },
    // 上传失败
    handleErrorQuill() {
      this.loading = false
      this.$Message.error('文件上传失败')
      // this.$Notice.error({
      // 	title: '失败',
      // 	desc: '文件上传失败'
      // })
    },
    // 上传成功
    handleSuccessQuill(res) {
      // console.log(res)
      this.loading = false
      let key = res.data[0].file_key
      let file_name = res.data[0].file_name
      // 把key返回
      // this.$emit('get-key', key, file_name)
      this.$emit('get-key', key, file_name, res.data[0].file_url)
    },
    // 上传中
    handleProgressQuill() {
      this.loading = true
    },
  },
}
</script>

<style scoped>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
