<!--经营许可证-->
<template>
  <div>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="经营许可证" :showIcon="true" :fontColor="isUpdate('license_file_name')"></FromLabel>
        <div class="allWidth">
          <Input v-model="operating_license.license_file_name" :clearable="!is_readonly" @on-clear="clearUploadFile" readonly class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" label="上传" @get-key="getKey(...arguments, 'license_file_key')" :disabled="is_readonly" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile">{{ operating_license.license_file_name }}</span> -->
          <span class="lookupImg" v-if="operating_license.company_name && operating_license.license_file_key" @click="openFile">{{ operating_license.company_name }}-经营许可证</span>
          <span class="lookupImg" v-else-if="!operating_license.company_name && operating_license.license_file_key" @click="openFile">经营许可证</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="有效期限" :showIcon="true" :fontColor="isUpdate('business_start_date') || isUpdate('business_end_date') || isUpdate('is_long_term')"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="operating_license.business_start_date" @on-change="changeTime($event, 1)" :readonly="is_readonly"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165" :value="operating_license.business_end_date" @on-change="changeTime($event, 2)" :options="options" :readonly="operating_license.is_long_term == 1 || is_readonly"></DatePicker>
          <Checkbox v-model="operating_license.is_long_term" @on-change="onChange" :true-value="1" :false-value="0" style="margin-left: 20px;" :disabled="is_readonly">长期</Checkbox>
        </div>
        <FromLabel label="许可证编号" :showIcon="true" :fontColor="isUpdate('operating_license_code')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="operating_license.operating_license_code" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业名称" :showIcon="true" :fontColor="isUpdate('company_name')"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" :disabled="Boolean(is_approved)" v-model="operating_license.company_name" :readonly="is_readonly"></i-input>
        </div>
        <FromLabel label="经营方式" :showIcon="true" :fontColor="isUpdate('operating_type')"></FromLabel>
        <div class="formFrIpt fl">
          <RadioGroup v-model="operating_license.operating_type">
            <Radio label="1" :disabled="is_readonly">批发</Radio>
            <Radio label="2" :disabled="is_readonly">零售</Radio>
            <Radio label="3" :disabled="is_readonly">批发兼零售</Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true" :fontColor="isUpdate('legal_person')"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="operating_license.legal_person" :readonly="is_readonly"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true" :fontColor="isUpdate('incharge_person')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="operating_license.incharge_person" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="住所" :showIcon="true" :fontColor="isUpdate('residence')"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="operating_license.residence" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="经营场所" :showIcon="true" :fontColor="isUpdate('premise')"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="operating_license.premise" :readonly="is_readonly"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="库房地址" :showIcon="true" :fontColor="isUpdate('warehouse_address')"></FromLabel>
        <div class="allWidth">
          <i-input type="text" placeholder="请输入" class="iviewIptWidth1075" maxlength="200" show-word-limit v-model="operating_license.warehouse_address" :readonly="is_readonly"></i-input>
        </div>
      </div>
    </div>
    <div class="tableTitle">
      <span class="title" :class="{ redColor: isUpdate('scope_list') }">
        经营范围
      </span>
      <span class="longTextBtn finger btnReset fr" @click="addBill"> 新增 </span>
    </div>
    <Table :productList="listColumns" :productData="operating_license.scope_list"></Table>

    <!-- 新增 -->
    <Modal v-model="add" title="新增" footer-hide width="1200">
      <Form inline class="form">
        <FormItem>
          <span class="label">2017版目录：</span>
          <Select clearable filterable v-model="catalogue" class="iviewIptWidth250 marginRight10">
            <Option v-for="(item, index) in catalogueList" :value="item.scope2017" :key="index">{{ item.scope2017 }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="search">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="confirm">确定</span>
        </FormItem>
      </Form>
      <Table v-if="add" :productList="addListColumns" height="500" :showSizer="true" :showElevator="true" :productData="addListData" :total="addTotal" :pages="addPages" :isLoad="addIsLoad" totalText="条记录" @on-page-size-change="onPageSizeChange" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" @change-page="changePage" ref="customTable"></Table>
    </Modal>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
import Table from '@/components/table'
export default {
  name: 'operatingLicense',
  components: {
    FromLabel,
    FormUpload,
    Table,
  },
  data() {
    return {
      data: {},
      // 弹窗-非全选，false表是非全选状态
      isAll: false,
      // 弹窗-暂存勾选项
      selectedItem: [],
      // 弹窗-搜索-下拉列表
      catalogueList: [],
      // 弹窗-搜索
      catalogue: '',
      addIsLoad: false,
      addTotal: 0,
      addPages: {
        page: 1,
        rows: 10,
      },
      // 弹窗-表格全部数据
      addListData: [],
      // 弹窗-表头
      addListColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '2017版医疗器械分类目录',
          key: 'scope2017',
          align: 'center',
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'scope2002',
          align: 'center',
        },
      ],
      // 弹窗-新增
      add: false,

      options: {},
      // 经营许可证
      operating_license: {
        scope_list: [],
      },
      isLoad: false,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'scope2002',
          align: 'center',
        },
        {
          title: '2017版医疗器械分类目录',
          key: 'scope2017',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteBill(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },
    }
  },
  mounted() {
    if (this.$route.query.supplier_id) {
      this.data = {
        supplier_id: this.$route.query.supplier_id,
        table_type: 5,
      }
    } else {
      this.data = {
        table_type: 5,
      }
    }
  },
  props: {
    operating_license_props: Object,
    o_near_update_field: Array,
    is_readonly: Boolean || false,
    is_approved: Number,
  },
  watch: {
    // 初始化 operating_license 值
    operating_license_props: {
      handler(newValue, oldValue) {
        // 如果截止日期为'9999-12-31'则置空
        if (newValue.business_end_date == '9999-12-31') {
          newValue.business_end_date = null
        }
        // 处理单选-长期
        if (!newValue.is_long_term) {
          newValue.is_long_term = 0
        }
        newValue.operating_type = String(newValue.operating_type)
        newValue.scope_list = !newValue.scope_list || newValue.scope_list === '' ? [] : newValue.scope_list
        this.operating_license = newValue
      },
      deep: true,
      immediate: true,
    },
    // 侦听对象-深度侦听-子传父
    operatingLicense: {
      handler(newValue, oldValue) {
        let syncField = []
        // 企业名称
        if (newValue.company_name != oldValue.company_name) {
          syncField = ['company_name', newValue.company_name]
        }
        // 法人
        if (newValue.legal_person != oldValue.legal_person) {
          syncField = ['legal_person', newValue.legal_person]
        }
        // 住所
        if (newValue.residence != oldValue.residence) {
          syncField = ['residence', newValue.residence]
        }
        // 经营场所
        if (newValue.premise != oldValue.premise) {
          syncField = ['premise', newValue.premise]
        }
        // 库房地址
        if (newValue.warehouse_address != oldValue.warehouse_address) {
          syncField = ['warehouse_address', newValue.warehouse_address]
        }
        // 经营方式
        if (newValue.operating_type != oldValue.operating_type) {
          syncField = ['operating_type', newValue.operating_type]
        }
        // 企业负责人
        if (newValue.incharge_person != oldValue.incharge_person) {
          syncField = ['incharge_person', newValue.incharge_person]
        }
        this.$emit('information', 'operating_license', this.operating_license, syncField)
      },
      deep: true,
    },
  },
  // 解决深度监听新旧值打印一样问题
  computed: {
    operatingLicense() {
      return JSON.parse(JSON.stringify(this.operating_license))
    },
  },
  methods: {
    // 修改的值标红
    isUpdate(field) {
      return this.o_near_update_field.includes(field)
    },
    // 弹窗-确定
    confirm() {
      // 按编号排序
      function compare(property) {
        return function (a, b) {
          let value1 = a[property]
          let value2 = b[property]
          return value1 - value2
        }
      }
      this.selectedItem.sort(compare('scope_id'))
      // 删除 _checked 键
      this.selectedItem.forEach(item => {
        delete item._checked
      })
      this.operating_license.scope_list = this.selectedItem
      this.add = false
      this.catalogue = ''
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾全选
    onSelectAll(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      // 赋值 暂存勾选项
      this.selectedItem = this.addListDataAll
      // 添加勾选字段
      this.selectedItem.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = true
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem.forEach((item, index) => {
        if (item.scope_id === row.scope_id) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾单选
    onSelect(data, row) {
      this.selectedItem.push(row)
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-回显勾选项
    selectedEcho(turnPage = false) {
      // 新增供应商时此字段是null，所以需要赋[]
      if (!this.operating_license.scope_list) {
        this.operating_license.scope_list = []
      }
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.operating_license.scope_list
      if (!turnPage) {
        this.selectedItem = [...this.operating_license.scope_list, ...this.selectedItem]
      }
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem = this.selectedItem.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.scope_id]) {
          cur.push(next)
          obj[next.scope_id] = next.scope_id
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.scope_id === item2.scope_id) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 经营范围目录 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-查询
    search() {
      this.addPages.page = 1
      let scope2017 = this.catalogue
      this.getAddBill(scope2017)
    },
    // 弹窗-改变每页显示页数
    onPageSizeChange(e) {
      this.addPages.rows = e
      this.catalogue = ''
      this.getAddBill()
    },
    // 弹窗-翻页
    changePage(e) {
      this.addPages.page = e
      this.getAddBill('', true)
    },
    // 弹窗-获取 下拉列表 数据
    getAddBillDown() {
      this.$http.get(this.$api.supplierBusinessscopeList).then(res => {
        this.catalogueList = res.data
      })
    },
    // 弹窗-获取 目录 数据
    getAddBill(scope2017 = '', turnPage = false) {
      let data = {
        scope2017: scope2017 || this.catalogue,
        page: this.addPages.page,
        rows: this.addPages.rows,
      }
      this.$http.get(this.$api.supplierBusinessscope, data).then(res => {
        this.addListData = res.data.result
        this.addTotal = res.data.total
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho(turnPage)
      })
    },
    // 弹窗-获取 目录 全部数据
    getAddBillAll() {
      this.$http.get(this.$api.supplierBusinessscope, { page: 1, rows: 10000 }).then(res => {
        this.addListDataAll = res.data.result
      })
    },
    // 弹窗-显示
    addBill() {
      this.add = true
      // 初始化页码
      this.addPages = {
        page: 1,
        rows: 10,
      }
      // 初始化搜索框
      this.catalogue = ''
      // 初始化 暂存勾选项
      this.selectedItem = []
      this.getAddBillAll()
      this.getAddBill()
      this.getAddBillDown()
    },

    // 删除某项 经营范围
    deleteBill(index) {
      this.operating_license.scope_list.splice(index, 1)
    },
    // 选择 长期 时清空截止日期
    onChange() {
      this.operating_license.business_end_date = ''
    },
    // 改变日期
    changeDate(field, e) {
      this.operating_license[field] = e
    },
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.operating_license.business_start_date = e
        if (e > this.operating_license.business_end_date) {
          this.operating_license.business_end_date = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.operating_license.business_start_date) - 86400000
          },
        }
      } else {
        this.operating_license.business_end_date = e
      }
    },
    // 打开上传的文件
    openFile() {
      window.open(this.operating_license.license_file_key_url)
    },
    // 清空上传文件
    clearUploadFile() {
      delete this.operating_license.license_file_key
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url, field) {
      // console.log(key, file_name, field)
      if (field === 'license_file_key') {
        this.operating_license.license_file_key = key
        this.operating_license.license_file_key_url = url
        this.operating_license.license_file_name = file_name
      }
    },
  },
}
</script>

<style scoped lang="less">
.lookupImg {
  width: 200px;
}

.form {
  /deep/.ivu-form-item {
    margin-bottom: 16px;
  }
}

.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 68px;
    transform: translate(50%, -50%);
  }
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.tableTitle {
  margin: 17px 0 20px 0;

  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}

.moreHeight {
  height: 100px;
}

.formLabel {
  height: 100px !important;
  line-height: 100px !important;
}
</style>
